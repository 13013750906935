class StatsGraph {
  constructor(game) {
    this.game = game;

    this.bunnyAmountData = [];
    this.plantAmountData = [];
    this.averagePlantNutrientData = [];
    this.averageBunnySpeedData = [];

    this.initialBunnyAmount = this.game.bunnies.length;
    this.initialPlantAmount = this.game.plants.length;

    let totalBunnyWaitBetweenSteps = 0;
    this.game.bunnies.forEach(bunny => {
      totalBunnyWaitBetweenSteps += bunny.waitBetweenSteps;
    });

    this.initialBunnyWaitBetweenSteps = totalBunnyWaitBetweenSteps / this.game.bunnies.length / 4;

    let totalNutrition = 0;
    this.game.plants.forEach(plant => {
      totalNutrition += plant.maxNutrition;
    });

    this.initialPlantNutritionAverage = totalNutrition / this.game.plants.length / 2;

    this.canvas = document.getElementById("statsGraph");
    this.ctx = this.canvas.getContext("2d");
    this.ctx.lineWidth = 1;

    //Substract the border
    this.canvasWidth = this.canvas.offsetWidth - 2;

    this.draw();
    this.setUpdateInterval = setInterval(() => {
      this.update();
    }, 500);
  }

  update() {
    this.bunnyAmountData.push(this.game.bunnies.length);
    this.plantAmountData.push(this.game.plants.length);

    if (this.bunnyAmountData.length > 10000) {
      this.bunnyAmountData.splice(0, 1000);
    }

    if (this.plantAmountData.length > 10000) {
      this.plantAmountData.splice(0, 1000);
    }

    if (this.averagePlantNutrientData.length > 10000) {
      this.averagePlantNutrientData.splice(0, 1000);
    }

    let totalNutrition = 0;
    this.game.plants.forEach(plant => {
      totalNutrition += plant.maxNutrition;
    });

    this.averagePlantNutrientData.push(totalNutrition / this.game.plants.length / 2);

    let totalBunnyWaitBetweenSteps = 0;
    this.game.bunnies.forEach(bunny => {
      totalBunnyWaitBetweenSteps += bunny.waitBetweenSteps;
    });

    this.averageBunnySpeedData.push(totalBunnyWaitBetweenSteps / this.game.bunnies.length / 4);

    this.draw();
  }

  draw() {
    this.ctx.clearRect(0, 0, 880, 200);
    this.labelYAxis();
    this.drawGraph(this.bunnyAmountData, this.initialBunnyAmount, "#FF0000");
    this.drawGraph(this.plantAmountData, this.initialPlantAmount, "#009900");
    this.drawGraph(this.averagePlantNutrientData, this.initialPlantNutritionAverage, "#00DDDD");
    this.drawGraph(this.averageBunnySpeedData, this.initialBunnyWaitBetweenSteps, "#DD00DD");
    this.drawLegend();
  }

  drawLegendElement(text, color) {
    this.ctx.fillStyle = color;
    this.ctx.fillRect(10, this.legendX, 10, 10);
    this.ctx.fillStyle = "#000000";
    this.ctx.fillText(text, 25, this.legendX + 8);
    this.legendX += 15;
  }

  drawLegend() {
    this.legendX = 10;
    this.ctx.textAlign = "left";
    this.drawLegendElement("Total Bunnies", "#FF0000");
    this.drawLegendElement("Total Plants", "#009900");
    this.drawLegendElement("Average Plantnutrition (/2)", "#00DDDD");
    this.drawLegendElement("Average Bunny Speed (/4)", "#DD00DD");
  }

  labelYAxis() {
    this.ctx.strokeStyle = "#dedede";
    this.ctx.fillStyle = "#000000";
    this.ctx.textAlign = "right";

    for (let i = 0; i <= 10; i++) {
      this.ctx.beginPath();
      this.ctx.moveTo(0, i * 20);
      this.ctx.lineTo(this.canvasWidth, i * 20);
      this.ctx.stroke();
      if (i !== 0 && i !== 10) {
        this.ctx.fillText((10 - i) * 20, this.canvasWidth - 5, i * 20 + 4);
      }
      this.ctx.closePath();
    }
  }

  drawGraph(data, initialAmount, color) {
    this.ctx.strokeStyle = color;
    let x = 0;
    this.ctx.beginPath();

    if (data.length < 101) {
      this.ctx.moveTo(x, 200 - initialAmount);
      data.forEach(dataPoint => {
        this.ctx.lineTo(x, 200 - dataPoint);
        x += this.canvasWidth / 100;
      });
    } else {
      this.ctx.moveTo(x, 200 - data[data.length - 100]);
      for (let i = data.length - 101; i < data.length; i++) {
        this.ctx.lineTo(x, 200 - data[i]);
        x += this.canvasWidth / 100;
      }
    }

    this.ctx.stroke();
    this.ctx.closePath();
  }
}
