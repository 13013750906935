class Stats {
  constructor(game) {
    this.game = game;
    this.logElement = document.getElementById("stats");

    this.availableNutrition = 0;

    this.bunnyDeaths = {
      total: 0,
      starvation: 0,
      thirst: 0,
      exhaustion: 0
    };
    this.bunniesBorn = {
      male: 0,
      female: 0,
      total: 0
    };
    this.plants = {
      grown: 0,
      alive: 0,
      eaten: 0
    };
  }

  refreshStats() {
    this.bunnyCountTotal = this.game.bunnies.length;
    this.plants.alive = this.game.plants.length;

    this.bunnyCountFemale = 0;
    this.bunnyCountMale = 0;
    for (let i = 0; i < this.game.bunnies.length; i++) {
      const bunny = this.game.bunnies[i];
      if (bunny.gender) {
        this.bunnyCountMale++;
      } else {
        this.bunnyCountFemale++;
      }
    }

    this.availableNutrition = 0;
    for (let i = 0; i < this.game.plants.length; i++) {
      const plant = this.game.plants[i];
      this.availableNutrition += plant.nutrition;
    }
  }

  update() {
    this.refreshStats();
    let string = `
      <h2>Stats</h2><br>
      Bunnies alive: ${this.bunnyCountTotal}<br>
      &nbsp;&nbsp;Male: ${this.bunnyCountMale}<br>
      &nbsp;&nbsp;Female: ${this.bunnyCountFemale}<br><br>

      Bunnies born: ${this.bunniesBorn.total}<br>
      &nbsp;&nbsp;Male: ${this.bunniesBorn.male}<br>
      &nbsp;&nbsp;Female: ${this.bunniesBorn.female}<br><br>

      Bunnies died: ${this.bunnyDeaths.total}<br>
      &nbsp;&nbsp;Starvation: ${this.bunnyDeaths.starvation}<br>
      &nbsp;&nbsp;Thirst: ${this.bunnyDeaths.thirst}<br>
      &nbsp;&nbsp;Exhaustion: ${this.bunnyDeaths.exhaustion}<br><br>

      Plants alive: ${this.plants.alive}<br>
      Plants eaten: ${this.plants.eaten}<br>
      Plants grown: ${this.plants.grown}<br>
      Available Nutrition: ${Math.round(this.availableNutrition)}
    `;

    this.logElement.innerHTML = string;
  }
}
