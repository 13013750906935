class Plant {
  constructor(game, nutrition) {
    this.game = game;
    this.nutrition = nutrition == undefined ? Math.floor(Math.random() * 100) + 100 : nutrition;
    this.maxNutrition = this.nutrition;
    this.sprite = new Image();
    this.sprite.src = "./../res/img/plant.png";
    this.setStartPosition();
    this.setRandomId();
  }

  /**
   * Sets the ID of the Plant to a unique number
   * @returns {void}
   */
  setRandomId() {
    let randomId = Math.floor(Math.random() * 10000);
    for (let i = 0; i < this.game.plants.length; i++) {
      const plant = this.game.plants[i];
      if (plant.id == randomId) {
        return this.setRandomId();
      }
    }
    this.id = randomId;
  }

  setStartPosition() {
    let x = Math.floor(Math.random() * 40);
    let y = Math.floor(Math.random() * 40);
    if (this.checkIfTileIsFree(x, y)) {
      this.x = x;
      this.y = y;
    } else {
      this.setStartPosition();
    }
  }

  /**
   * Checks if a given tile contains a plant,
   * water or a bunny and returns fals if so
   * @returns {Boolean} true is tile is not occupied and not out of bounds
   */
  checkIfTileIsFree(x, y) {
    if (x >= 40 || y >= 40 || x < 0 || y < 0) {
      return false;
    }

    for (let i = 0; i < this.game.waterTiles.length; i++) {
      const water = this.game.waterTiles[i];
      if (water[0] === x && water[1] === y) {
        return false;
      }
    }

    for (let i = 0; i < this.game.plants.length; i++) {
      const plant = this.game.plants[i];
      if (plant.x === x && plant.y === y) {
        return false;
      }
    }

    for (let i = 0; i < this.game.bunnies.length; i++) {
      const bunny = this.game.bunnies[i];
      if ((bunny.x === x && bunny.y === y) || (bunny.targetX === x && bunny.targetY === y)) {
        return false;
      }
    }

    return true;
  }

  draw() {
    this.game.ctx.drawImage(this.sprite, this.x * 16, this.y * 16, 16, 16);
    this.game.ctx.fillStyle = "#FF0000";
    this.game.ctx.fillRect((this.x + 1.1) * 16, this.y * 16 + 16 - (this.nutrition / this.maxNutrition) * 16, 2, (this.nutrition / this.maxNutrition) * 16);
  }

  destroy() {
    for (let i = 0; i < this.game.plants.length; i++) {
      const plant = this.game.plants[i];
      if (plant.id === this.id) {
        this.game.plants.splice(i, 1);
      }
    }
  }

  update() {
    this.draw();
  }
}
