class PlantSpawner {
  constructor(game) {
    this.game = game;
    this.lastPlantSpawn = Date.now();
    this.spawnTimeRange = {
      min: 1500,
      max: 2500
    };
    this.maxPlantAmount = 140;
    this.nextSpawn = Math.floor(Math.random() * this.spawnTimeRange.min) + (this.spawnTimeRange.max - this.spawnTimeRange.min);

    //Add initial plants
    for (let i = 0; i < 50; i++) {
      this.game.plants.push(new Plant(this.game));
    }
  }

  spawnNewPlant() {
    if (Date.now() - this.lastPlantSpawn > this.nextSpawn && this.game.plants.length < this.maxPlantAmount) {
      this.nextSpawn = ((Math.floor(Math.random() * this.spawnTimeRange.min) + (this.spawnTimeRange.max - this.spawnTimeRange.min)) * this.game.plants.length) / 50;
      let nutrition = this.nextSpawn/15;
      this.game.plants.push(new Plant(this.game, nutrition));
      
      this.lastPlantSpawn = Date.now();
      this.game.stats.plants.grown++;
    }
  }

  update() {
    this.spawnNewPlant();
  }
}
