class Game {
  /**
   * Constructor, initializes the interval and starts the game
   */
  constructor() {
    //GENERAL SETTINGS
    this.fps = 60;

    //CANVAS SETTINGS
    this.canvas = document.getElementById("game");
    this.ctx = this.canvas.getContext("2d");
    this.canvasWidth = 640;
    this.canvasHeight = 640;

    this.waterTiles = [];
    this.bunnies = [];
    this.plants = [];
    this.deaths = {};

    //TIME SETTINGS
    this.time = {
      startTime: Date.now(),
      playDuration: 0,
      lastFrameDrawn: Date.now(),
      deltaTime: 0
    };

    //GAME OBJECTS
    this.heightmapLoader = new HeightmapLoader(this, () => {
      for (let i = 0; i < 30; i++) {
        this.bunnies.push(new Bunny(this));
      }

      this.plantSpawner = new PlantSpawner(this);
      this.stats = new Stats(this);
      this.statsGraph = new StatsGraph(this);

      //START INTERVAL
      this.updateInterval = setInterval(() => {
        this.update();
      }, 1000 / this.fps);
    });
  }

  /**
   * Clears the whole canvas
   */
  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  /**
   * Just a debug function to get data of bunnies
   * stuck in a certain position
   */
  logBunnyAtCoordinate(x, y) {
    for (let i = 0; i < this.bunnies.length; i++) {
      const bunny = this.bunnies[i];
      if (bunny.x === x && bunny.y === y) {
        return bunny;
      }
    }
  }

  /**
   * Just a debug function to get a bunny
   * with a given ID
   */
  getBunnyById(id) {
    for (let i = 0; i < this.bunnies.length; i++) {
      const bunny = this.bunnies[i];
      if (bunny.id === id) {
        return bunny;
      }
    }
  }

  /**
   * Will be called every Frame
   */
  update() {
    this.clearCanvas();
    this.heightmapLoader.draw();
    this.plantSpawner.update();

    this.plants.forEach(plant => {
      plant.update();
    });

    this.bunnies.forEach(bunny => {
      bunny.update();
    });

    this.stats.update();

    this.time.deltaTime = Date.now() - this.time.lastFrameDrawn;
    this.time.lastFrameDrawn = Date.now();
    this.time.playDuration = Date.now() - this.time.startTime;
  }
}
