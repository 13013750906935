class Bunny {
  constructor(game, x, y, grownup, gender, parents) {
    this.game = game;
    this.stepTimeout = undefined;
    this.grownup = grownup === undefined ? true : grownup;
    this.partner = undefined;
    this.parents = parents;

    this.gender = gender === undefined ? Math.round(Math.random()) : gender;

    if (this.grownup === false) {
      this.game.stats.bunniesBorn.total++;
      if (this.gender) {
        this.game.stats.bunniesBorn.male++;
      } else {
        this.game.stats.bunniesBorn.female++;
      }
    }

    this.bunnySprites = {
      male: new Image(),
      male_sleeping: new Image(),
      female: new Image(),
      female_sleeping: new Image()
    };

    this.bunnySprites.male.src = "./../res/img/bunny_male.png";
    this.bunnySprites.male_sleeping.src = "./../res/img/bunny_male_sleeping.png";
    this.bunnySprites.female.src = "./../res/img/bunny_female.png";
    this.bunnySprites.female_sleeping.src = "./../res/img/bunny_female_sleeping.png";

    this.sprite = new Image();
    this.sprite.src = "./../res/img/bunny_male.png";
    
    this.state = "moving";
    
    this.urgesSprites = {
      hunger: new Image(),
      thirst: new Image(),
      sleep: new Image(),
      love: new Image()
    };
    
    this.urgesSprites.hunger.src = "./../res/img/hunger.png";
    this.urgesSprites.thirst.src = "./../res/img/thirst.png";
    this.urgesSprites.sleep.src = "./../res/img/sleep.png";
    this.urgesSprites.love.src = "./../res/img/love.png";
    
    this.speed = 0.05;
    this.waitBetweenSteps = Math.floor(Math.random() * 250) + 300;
    
    this.impregnationDuration = 3000;
    this.averageAmountOfBabiesPerPregnancy = 5;
    
    this.sightDistance = 4;

    this.hungerPerStep = 3;
    this.thirstPerStep = 5;
    this.energyPerStep = 3;
    this.lovePerStep = 5;

    this.dieOfHunger = 300;
    this.dieOfThirst = 300;
    this.dieOfExhaust = 300;

    this.timeToGrowup = 15000;

    this.urges = {
      hunger: 100,
      thirst: 100,
      sleep: 0,
      love: 90
    };

    if (this.grownup === false) {
      if (this.parents) {
        this.getBorn();
      }

      setTimeout(() => {
        this.grownup = true;
      }, this.timeToGrowup);
    }

    if (x === undefined && y === undefined) {
      this.setStartPosition();
    } else {
      this.x = x;
      this.y = y;
      this.targetX = x;
      this.targetY = y;
    }

    this.setRandomId();
    this.setBiggestUrge();
  }

  /**
   * If the bunny is a newborn and not one of the initial bunnies
   * it will inherit some genes with a change of mutation
   * @returns {void}
   */
  getBorn() {
    this.speed = this.inheritAndMutateGenes("speed");
    this.waitBetweenSteps = this.inheritAndMutateGenes("waitBetweenSteps");
    this.impregnationDuration = this.inheritAndMutateGenes("impregnationDuration");
    this.averageAmountOfBabiesPerPregnancy = this.inheritAndMutateGenes("averageAmountOfBabiesPerPregnancy");
    this.hungerPerStep = this.inheritAndMutateGenes("hungerPerStep");
    this.thirstPerStep = this.inheritAndMutateGenes("thirstPerStep");
    this.energyPerStep = this.inheritAndMutateGenes("energyPerStep");
    this.lovePerStep = this.inheritAndMutateGenes("lovePerStep");
    this.dieOfHunger = this.inheritAndMutateGenes("dieOfHunger");
    this.dieOfThirst = this.inheritAndMutateGenes("dieOfThirst");
    this.dieOfExhaust = this.inheritAndMutateGenes("dieOfExhaust");
    this.timeToGrowup = this.inheritAndMutateGenes("timeToGrowup");
    this.sightDistance = this.inheritAndMutateGenes("sightDistance");
  }

  /**
   * Sets the ID of the Bunny to a unique number
   * @returns {void}
   */
  setRandomId() {
    let randomId = Math.floor(Math.random() * 10000);
    for (let i = 0; i < this.game.bunnies.length; i++) {
      const bunny = this.game.bunnies[i];
      if (bunny.id == randomId) {
        return this.setRandomId();
      }
    }
    this.id = randomId;
  }

  /**
   * When born, the bunny has a chance to mutate
   * a gene inherited from the father or mother
   * @param {String} gene The name of the gene
   * @returns {Number} The new and (maybe) mutated gene-value
   */
  inheritAndMutateGenes(gene) {
    let dominantGene = Math.round(Math.random());
    let dominantParent = dominantGene ? this.parents.father : this.parents.mother;
    let changeInGene = Math.floor(Math.random() * 3) - 1;
    let newGeneValue = dominantParent[gene] + (dominantParent[gene] / 100) * changeInGene;
    return newGeneValue;
  }

  /**
   * Sets the largest urge to the most important one
   * @returns {void}
   */
  setBiggestUrge() {
    if (this.urges.sleep > 250) {
      this.biggestUrge = "sleep";
    } else if (this.urges.hunger > this.urges.thirst && this.urges.hunger > 100) {
      this.biggestUrge = "hunger";
    } else if (this.urges.thirst > this.urges.hunger && this.urges.thirst > 100) {
      this.biggestUrge = "thirst";
    } else if (this.urges.love > 100 && this.grownup) {
      this.biggestUrge = "love";
    } else {
      this.biggestUrge = undefined;
    }
  }

  /**
   * Sets the closest field that can satifsfy the srtongest need
   * @returns {void}
   */
  getClosestTileOfBiggestUrge() {
    let fieldsInView = this.getFieldsInView();
    for (let i = 0; i < fieldsInView.length; i++) {
      const field = fieldsInView[i];
      if (this.biggestUrge === "hunger") {
        if (this.partner) {
          this.partner.partner = undefined;
          this.partner = undefined;
        }
        for (let j = 0; j < this.game.plants.length; j++) {
          const plant = this.game.plants[j];
          if (plant.x === field[0] && plant.y === field[1]) {
            this.adjacentFood = this.game.plants[j];
            let goal = this.getClosestAdjacentField(plant.x, plant.y);
            this.urgeGoalTile = [goal[0], goal[1]];
            return true;
          }
        }
      } else if (this.biggestUrge === "thirst") {
        if (this.partner) {
          this.partner.partner = undefined;
          this.partner = undefined;
        }
        for (let j = 0; j < this.game.waterTiles.length; j++) {
          const water = this.game.waterTiles[j];
          if (water[0] === field[0] && water[1] === field[1]) {
            let goal = this.getClosestAdjacentField(water[0], water[1]);
            this.urgeGoalTile = [goal[0], goal[1]];
            return true;
          }
        }
      } else if (this.biggestUrge === "love" && this.gender) {
        for (let j = 0; j < this.game.bunnies.length; j++) {
          const bunny = this.game.bunnies[j];
          if (bunny.x === field[0] && bunny.y === field[1]) {
            if (bunny.gender === 0 && bunny.biggestUrge === "love" && (bunny.partner === undefined || bunny.partner.id === this.id)) {
              this.partner = bunny;
              bunny.partner = this;
              bunny.state = "expect_male";
              bunny.x = Math.round(bunny.x);
              bunny.y = Math.round(bunny.y);
              let goal = this.getClosestAdjacentField(bunny.x, bunny.y);
              this.urgeGoalTile = [goal[0], goal[1]];
              return true;
            }
          }
        }
      }
      this.urgeGoalTile = undefined;
    }
  }

  /**
   * Returns field that is closest to the bunny and adjacent to the target-tile
   * @param {Number} x X-Coordinate of the target-tile
   * @param {Number} y Y-Coordinate of the target-tile
   * @returns {void}
   */
  getClosestAdjacentField(x, y) {
    let goalX;
    let goalY;
    if (x > this.x) {
      goalX = x - 1;
    } else if (x < this.x) {
      goalX = x + 1;
    } else {
      goalX = x;
    }

    if (y > this.y) {
      goalY = y - 1;
    } else if (y < this.y) {
      goalY = y + 1;
    } else {
      goalY = y;
    }
    return [goalX, goalY];
  }

  /**
   * Sets a random, free starting position
   * @returns {void}
   */
  setStartPosition() {
    let x = Math.floor(Math.random() * 40);
    let y = Math.floor(Math.random() * 40);
    if (this.checkIfTileIsFree(x, y)) {
      this.x = x;
      this.y = y;
      this.targetX = x;
      this.targetY = y;
    } else {
      this.setStartPosition();
    }
  }

  /**
   * Checks if the partner is still
   * interested and removes it if not
   * @returns {Boolean} true if partner is interested, false otherwise
   */
  checkIfPartnerStillIsInterested() {
    for (let i = 0; i < this.bunnies.length; i++) {
      const bunny = this.bunnies[i];
      if (bunny.id === this.partner.id && bunny.partner.id === this.id && bunny.biggestUrge === "love") {
        //All good, partner is still alive and looking for love
        return true;
      }
    }
    //Sorry your Partner is no longer interested or died
    this.partner = undefined;
    this.state === "moving";
    return false;
  }

  /**
   * Returns a free adjacent field
   * @returns {array} an Array containing x and y of a free adjacent field
   */
  getRandomFreeAdjacentField() {
    let adjacentFields = [
      [this.x - 1, this.y - 1],
      [this.x, this.y - 1],
      [this.x + 1, this.y - 1],
      [this.x - 1, this.y],
      [this.x + 1, this.y],
      [this.x - 1, this.y + 1],
      [this.x, this.y + 1],
      [this.x + 1, this.y + 1]
    ];
    let field = Math.floor(Math.random() * adjacentFields.length);
    let isFree = this.checkIfTileIsFree(adjacentFields[field][0], adjacentFields[field][1]);

    if (isFree) {
      return adjacentFields[field];
    } else {
      return this.getRandomFreeAdjacentField();
    }
  }

  /**
   * Checks if a given tile is free to move on
   * @param {Number} x X-Coordinate of the tile that should be tested
   * @param {Number} y Y-Coordinate of the tile that should be tested
   * @returns {Boolean} false if tile is occupied (or out of bounds), true if the tile is free
   */
  checkIfTileIsFree(x, y) {
    if (x >= 40 || y >= 40 || x < 0 || y < 0) {
      return false;
    }

    for (let i = 0; i < this.game.waterTiles.length; i++) {
      const water = this.game.waterTiles[i];
      if (water[0] === x && water[1] === y) {
        return false;
      }
    }

    for (let i = 0; i < this.game.bunnies.length; i++) {
      const bunny = this.game.bunnies[i];
      if (((bunny.x === x && bunny.y === y) || (bunny.targetX === x && bunny.targetY === y)) && bunny.grownup) {
        setTimeout(() => {
          return false;
        }, 1000);
      }
    }

    return true;
  }

  /**
   * Sets the target-tile for the bunny
   * @param {Number} x X-Coordinate of the new Movement-Target
   * @param {Number} y Y-Coordinate of the new Movement-Target
   * @returns {void}
   */
  setTargetTile(x, y) {
    this.targetX = x;
    this.targetY = y;
  }

  /**
   * Returns all fields in the view of the bunny in a clockwise outwards spiral
   * @returns {Array} The fields in the view of the bunny
   */
  getFieldsInView() {
    fieldsInView = [];

    let xOffset = this.x;
    let yOffset = this.y;
    let direction = ["up", "right", "down", "left"];
    let directionIndex = 0;

    for (let i = 1; i <= this.sightDistance * 2 + 1; i++) {
      let loopLength = i > this.sightDistance * 2 ? 1 : 2;
      for (let j = 0; j < loopLength; j++) {
        for (let k = 1; k <= i; k++) {
          if (direction[directionIndex % 4] === "up") {
            yOffset -= 1;
            fieldsInView.push([xOffset, yOffset]);
          } else if (direction[directionIndex % 4] === "right") {
            xOffset += 1;
            fieldsInView.push([xOffset, yOffset]);
          } else if (direction[directionIndex % 4] === "down") {
            yOffset += 1;
            fieldsInView.push([xOffset, yOffset]);
          } else if (direction[directionIndex % 4] === "left") {
            xOffset -= 1;
            fieldsInView.push([xOffset, yOffset]);
          }
        }
        directionIndex++;
      }
    }

    return fieldsInView;
  }

  /**
   * Moves the bunny towards its target-tile, starts a timeout and
   * sets a new target.
   * @returns {void}
   */
  move() {
    this.x = +this.x.toFixed(2);
    this.y = +this.y.toFixed(2);

    if (this.x < this.targetX) {
      this.x += this.speed;
    } else if (this.x > this.targetX) {
      this.x -= this.speed;
    }

    if (this.y < this.targetY) {
      this.y += this.speed;
    } else if (this.y > this.targetY) {
      this.y -= this.speed;
    }

    if (this.x == this.targetX && this.y == this.targetY) {
      if (this.stepTimeout == undefined) {
        this.stepTimeout = setTimeout(() => {
          if (this.urgeGoalTile === undefined) {
            let field = this.getRandomFreeAdjacentField();
            if (this.biggestUrge === "sleep") {
              this.state = "sleeping";
            } else {
              this.setTargetTile(field[0], field[1]);
            }
          } else {
            let targetX = this.x;
            let targetY = this.y;
            if (this.x > this.urgeGoalTile[0]) {
              targetX--;
            } else if (this.x < this.urgeGoalTile[0]) {
              targetX++;
            }

            if (this.y > this.urgeGoalTile[1]) {
              targetY--;
            } else if (this.y < this.urgeGoalTile[1]) {
              targetY++;
            }

            this.setTargetTile(targetX, targetY);

            if (this.urgeGoalTile[0] === this.x && this.urgeGoalTile[1] === this.y) {
              if (this.biggestUrge === "thirst") {
                this.state = "drinking";
              } else if (this.biggestUrge === "hunger") {
                this.state = "eating";
              } else if (this.biggestUrge === "love" && this.partner && this.gender) {
                this.state = "breeding";
              }
            }
          }

          this.urges.hunger += this.hungerPerStep;
          this.urges.thirst += this.thirstPerStep;
          this.urges.sleep += this.energyPerStep;
          this.urges.love += this.lovePerStep;

          if (this.urges.hunger > this.dieOfHunger) {
            this.die("starvation");
          } else if (this.urges.thirst > this.dieOfThirst) {
            this.die("thirst");
          } else if (this.urges.sleep > this.dieOfExhaust) {
            this.die("exhaustion");
          }

          this.stepTimeout = undefined;
        }, this.waitBetweenSteps);
      }
    }
  }

  /**
   * Draws the sprite to the canvas
   * @returns {void}
   */
  draw() {
    if (this.biggestUrge) {
      this.game.ctx.drawImage(this.urgesSprites[this.biggestUrge], this.x * 16, (this.y - 1.2) * 16, 16, 16);
    }

    let sprite = this.getCurrentSprite();

    if (this.grownup) {
      this.game.ctx.drawImage(sprite, this.x * 16, this.y * 16, 16, 16);
    } else {
      this.game.ctx.drawImage(sprite, this.x * 16 + 2, this.y * 16 + 2, 14, 14);
    }

    this.game.ctx.fillStyle = "#FF0000";
    this.game.ctx.fillRect(this.x * 16, (this.y + 1.1) * 16, ((this.dieOfHunger - this.urges.hunger) / this.dieOfHunger) * 16, 2);
    this.game.ctx.fillStyle = "#1A3CFF";
    this.game.ctx.fillRect(this.x * 16, (this.y + 1.3) * 16, ((this.dieOfThirst - this.urges.thirst) / this.dieOfThirst) * 16, 2);
    this.game.ctx.fillStyle = "yellow";
    this.game.ctx.fillRect(this.x * 16, (this.y + 1.5) * 16, ((this.dieOfExhaust - this.urges.sleep) / this.dieOfExhaust) * 16, 2);
  }

  getCurrentSprite() {
    if (this.gender) {
      if (this.state === "sleeping") {
        return this.bunnySprites.male_sleeping;
      }
      return this.bunnySprites.male;
    } else {
      if (this.state === "sleeping") {
        return this.bunnySprites.female_sleeping;
      }
      return this.bunnySprites.female;
    }
  }

  /**
   * Removes the bunny from the game
   * @param {String} reason The reason the bunny died (only for statistics)
   * @returns {Boolean} true if a bunny was removed, false otherwise
   */
  die(reason) {
    for (let i = 0; i < this.game.bunnies.length; i++) {
      const bunny = this.game.bunnies[i];
      if (bunny.id === this.id) {
        if (bunny.partner) {
          bunny.partner.partner = undefined;
        }
        this.game.bunnies.splice(i, 1);
        this.game.stats.bunnyDeaths["total"]++;
        this.game.stats.bunnyDeaths[reason]++;
        return true;
      }
    }
    return false;
  }

  /**
   * Eating a plant given as a parameter
   * @param {Plant} plant The plant the bunny is eating
   * @returns {void}
   */
  eat(plant) {
    if (this.eatingInterval === undefined) {
      this.eatingInterval = setInterval(() => {
        plant.nutrition -= 5;
        this.urges.hunger -= 5;
        if (plant.nutrition <= 0 || this.urges.hunger <= 0) {
          if (plant.nutrition <= 0) {
            plant.destroy();
            this.game.stats.plants.eaten++;
          }
          clearInterval(this.eatingInterval);
          this.eatingInterval = undefined;
          this.state = "moving";
        }
      }, 50);
    }
  }

  /**
   * Drinking until thirst is quenched
   * @returns {void}
   */
  drink() {
    if (this.drinkingInterval === undefined) {
      this.drinkingInterval = setInterval(() => {
        this.urges.thirst -= 5;
        if (this.urges.thirst <= 0) {
          clearInterval(this.drinkingInterval);
          this.drinkingInterval = undefined;
          this.state = "moving";
        }
      }, 50);
    }
  }

  /**
   * If breeding, there will be a random amount of babies born after
   * a set time.
   */
  breed() {
    if (this.breedingTimeout === undefined) {
      this.breedingTimeout = setTimeout(() => {
        this.breedingTimeout = undefined;
        let babyAmount = Math.floor(Math.random() * this.averageAmountOfBabiesPerPregnancy) + 1;
        for (let i = 0; i < babyAmount; i++) {
          this.game.bunnies.push(new Bunny(this.game, +this.partner.x.toFixed(0), +this.partner.y.toFixed(0), false, undefined, { father: this, mother: this.partner }));
        }

        this.partner.partner = undefined;
        this.partner.state = "moving";
        this.partner.urges.love = 0;
        this.partner.urgeGoalTile = undefined;

        this.state = "moving";
        this.partner = undefined;
        this.urges.love = 0;
        this.urgeGoalTile = undefined;
      }, this.impregnationDuration);
    }
  }

  sleep() {
    if (this.sleepingInterval === undefined) {
      this.sleepingInterval = setInterval(() => {
        this.urges.sleep -= 2;
        if (this.urges.sleep <= 0) {
          this.urges.sleep = 0;
          clearInterval(this.sleepingInterval);
          this.sleepingInterval = undefined;
          this.state = "moving";
        }
      }, 50);
    }
  }

  /**
   * Update is called on every frame
   * @returns {void}
   */
  update() {
    if (this.state === "moving") {
      this.setBiggestUrge();
      this.getClosestTileOfBiggestUrge();
      this.move();
    } else if (this.state === "drinking") {
      this.drink();
    } else if (this.state === "eating") {
      this.eat(this.adjacentFood);
    } else if (this.state === "sleeping") {
      this.sleep();
    } else if (this.state === "breeding") {
      this.breed();
    } else if (this.sate === "expect_male") {
      this.checkIfPartnerStillIsInterested();
    }

    this.draw();
  }
}
