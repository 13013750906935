class HeightmapLoader {
  constructor(game, callback) {
    this.callback = callback === undefined ? () => {} : callback;
    this.game = game;
    this.heightmapImage = new Image();
    this.heightmapImage.src = "./../res/img/heightmap_large.png";
    this.heightmapCanvas = document.createElement("canvas");
    this.heightmapContext = this.heightmapCanvas.getContext("2d");
    this.tiles = [];

    this.heightmapImage.addEventListener("load", () => {
      this.heightmapImageSize = {
        height: this.heightmapImage.height,
        width: this.heightmapImage.width
      };

      this.heightmapContext.drawImage(this.heightmapImage, 0, 0);
      document.querySelector("body").append(this.heightmapCanvas);

      for (let y = 0; y < this.heightmapImageSize.height; y++) {
        for (let x = 0; x < this.heightmapImageSize.width; x++) {
          let val = this.heightmapContext.getImageData(x, y, 1, 1).data[0];
          this.tiles.push(val);
          if (val < 40) {
            this.game.waterTiles.push([x, y]);
          }
        }
      }
      this.heightmapCanvas.remove();
      callback();
    });
  }

  draw() {
    this.tiles.forEach((tile, i) => {
      if (tile < 40) {
        this.game.ctx.fillStyle = `rgb(${tile}, ${4 + tile}, ${130 + tile})`;
      } else if (tile < 60) {
        this.game.ctx.fillStyle = `rgb(${235 - tile}, ${247 - tile}, ${106 - tile})`;
      } else {
        this.game.ctx.fillStyle = `rgb(${141 - tile}, ${205 - tile}, ${120 - tile})`;
      }
      this.game.ctx.fillRect((i % 40) * 16, Math.floor(i / 40) * 16, 16, 16);
    });
  }
}
